#root {
  width: 100%;
  min-width: 1200px;
}
.w1200 {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.w1200:after {
  display: block;
  content: '';
  clear: both;
  height: 0;
  overflow: hidden;
}
.m-pointer {
  cursor: pointer;
}
.min1200 {
  min-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.min1200:after {
  display: block;
  content: '';
  clear: both;
  height: 0;
  overflow: hidden;
}
.loading_example {
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.05);
  flex-direction: row;
  /* 子元素横向排列 */
  justify-content: center;
  /* 相对父元素水平居中 */
  align-items: center;
  /*  子元素相对父元素垂直居中 */
  position: absolute;
  z-index: 999;
}
.ant-layout {
  background: #ffffff;
}
.antd-header {
  padding: 0;
  height: 70px;
  background-color: #ffffff;
  -moz-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}
.antd-footer {
  height: 235px;
  padding: 0;
  background-color: #ffffff;
}
a {
  color: #ffffff;
}
.app-header {
  width: 100%;
  height: 100px;
}
.app-header:after {
  content: "";
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  position: absolute;
  bottom: 0;
}
.app-main {
  width: 100%;
  height: calc(100vh - 100px);
}
.app-main-left {
  width: 256px;
  height: 100%;
  position: absolute;
  overflow-y: auto;
  -webkit-transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.app-main-left:before {
  content: "";
  height: 100%;
  border-right: 1px solid #f0f0f0;
  position: absolute;
  right: 0;
}
.app-main-right {
  width: 100%;
  height: 100%;
  padding-left: 256px;
  -webkit-transition: padding-left 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  transition: padding-left 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.app-main-right .app-breadcrumb {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #f0f0f0;
}
.app-main-right .app-switch {
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
}
.app-main-left-small {
  width: 80px;
  -webkit-transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.app-main-right-small {
  padding-left: 80px;
  -webkit-transition: padding-left 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  transition: padding-left 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.p-navigation {
  margin-top: 20px;
  margin-bottom: 20px;
}
