html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
input {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

html {
	width: 100%;
	-webkit-tap-highlight-color: transparent;
}

body {
	width: 100%;
	height: 100%;
	font-family: "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
	-webkit-text-size-adjust: 100%;
}

article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
	display: block;
}

nav ul {
	list-style: none;
}

li {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

a {
	margin: 0;
	padding: 0;
	border: 0;
	text-decoration: none;
	vertical-align: baseline;
	background: transparent;
	color: inherit;
}

ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}

mark {
	background-color: #ff9;
	color: #000;
	font-style: italic;
	font-weight: bold;
}

del {
	text-decoration: line-through;
}

abbr[title],
dfn[title] {
	border-bottom: 1px dotted #000;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

img {
	vertical-align: middle;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #cccccc;
	margin: 1em 0;
	padding: 0;
}

input,
select {
	vertical-align: middle;
	font-family: "Microsoft YaHei",PingFangSC-Regular,Arial,Helvetica,sans-serif;
	font-size: 16px;
}

textarea {
	resize: none;
	outline: none;
	font-family: "Microsoft YaHei",PingFangSC-Regular,Arial,Helvetica,sans-serif;
}

select {
	border: none;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;


	outline: none;
}

select::-ms-expand {
	display: none;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}
.clearfix:after {
	clear: both;
}

.fl {
	float: left;
}
.fr {
	float: right;
}

.al-center {
	text-align: center;
}
.al-left {
	text-align: left;
}
.al-right {
	text-align: right;
}

.text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.pointer {
	cursor: pointer;
}

.p-relative {
	position: relative;
}
.p-fixed {
	position: fixed;
}
.p-absolute {
	position: absolute;
}

.d-block {
	display: block;
}
.d-inblock {
	display: inline-block;
}
.d-inline {
	display: inline;
}

.b-box {
	box-sizing: border-box;
}

.f-bold {
	font-weight: bold;
}
.f-normal {
	font-weight: normal;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-track {
    width: 6px;
    background: rgba(16,31,28, 0.1);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(16,31,28, 0.5);
    background-clip: padding-box;
    min-height: 28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(16,31,28, 1);
}